import Vue from "vue";
import VueRouter from "vue-router";

// 路由router
Vue.use(VueRouter);

const routes = [
  // 重定向
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: true,
    },
  },
  {
    path: "/classify",
    name: "classify",
    component: () => import("../views/Classify.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: true,
    },
  },
  {
    path: "/shopcart",
    name: "shopcart",
    component: () => import("../views/ShopCart.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: true,
    },
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/My.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: true,
    },
  },
  {
    path: "/scanqrcodes",
    name: "scanqrcodes",
    component: () => import("../views/components/ScanQrCodes.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  {
    path: "/scanoutwarehouse",
    name: "scanoutwarehouse",
    component: () => import("../views/components/ScanOutWarehouse.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  {
    path: "/completepickup",
    name: "completepickup",
    component: () => import("../views/components/CompletePickup.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  {
    path: "/progressquery",
    name: "progressquery",
    component: () => import("../views/components/ProgressQuery.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/components/Success.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Login.vue"),
    // 隐藏Tabbar组件
    meta: {
      showTab: false,
    },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  routes,
  // 配置路由跳转页面滚动到顶部
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// VUE router 导航重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// // 注册一个全局前置守卫
router.beforeEach((to, from, next) => {
  // to要访问的路径
  // from从哪个路径跳转过来
  // next是一个函数表示放行
  const token = localStorage.getItem("token");
  // 如果有token就放行
  if (token) {
    if (to.path === "/login") {
      next("/");
    } else {
      next();
    }
  } else {
    // 没有登录跳转登录页
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
