<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <!-- <MyTabbar v-if="this.$route.meta.showTab" /> -->
  </div>
</template>

<script>
// import MyTabbar from "@/components/Tabbar.vue";
export default {
  // components: {
  //   MyTabbar,
  // },
};
</script>

<style lang="less"></style>
