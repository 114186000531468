import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 移动端rem
import "amfe-flexible";

// 引入全局样式 global.css
import "./css/global.css";

// 全局导入过滤器
import filter from "./utils/filter.js";
Object.keys(filter).forEach((key) => Vue.filter(key, filter[key]));

// element-ui
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI);

// vant2 ui
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 模态框
import { Dialog } from "vant";
Vue.prototype.$dialog = Dialog;

// 轻提示
import { Toast } from "vant";
Vue.prototype.$toast = Toast;

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
